<template>
  <div class="audio-player">
    <span
      class="audio-player__icon"
      :class="{
        'icon-volume-mute': muted,
        'icon-volume-medium': !muted,
        muted: muted
      }"
      @click="togglePlayer"
    ></span>
    <p class="audio-player__title">Dreamer</p>
    <audio
      class="audio-player"
      ref="audioplayer"
      :autoplay="!muted"
      :volume="volume/100"
      loop
      :src="songFile"
    ></audio>
      <input type="range" min="1" max="100" v-model.number="volume" class="slider" id="myRange">

  </div>
</template>

<script>
import ArthursFist from "@/assets/audio/Dreamer.mp3";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
     
    }
  },
  data() {
    return {
        muted: true,
        volume: 50
    };
  },
  async mounted() {
    this.togglePlayer();
  },
  methods: {
    togglePlayer() {
      this.muted ? this.play() : this.pause();
    },
    async play() {
      const audioplayer = this.$refs.audioplayer;
      try {
        audioplayer.volume = 0;
        await audioplayer.play();
        this.muted = !this.muted;
        audioplayer.volume = this.volume/100;
      } catch {
        this.muted = true;
      }
    },
    async pause() {
      const audioplayer = this.$refs.audioplayer;
      try {
        audioplayer.volume = 0;
        await audioplayer.pause();
        this.muted = !this.muted;
        audioplayer.volume = 0.5;
      } catch {
        this.muted = true;
      }
    }
  },
  computed: {
    songFile() {
      return ArthursFist;
    }
  },
  watch: {
    active(value) {
      value ? this.play() : this.pause();
    },
    volume(value) {
        this.$refs.audioplayer.volume = value/100;
    }
  }
};
</script>

<style lang="scss">
.audio-player {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  font-size: 20px;
  color: $color-primary;
  z-index: 999;
  @media (max-width: 768px) {
    position: static;
    margin: 16px 0;
  }
  &__title {
    margin-left: 16px;
  }
  &__icon {
    cursor: pointer;
    &.muted {
      color: $color-e;
    }
  }
}
</style>
