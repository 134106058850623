import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import "@/scss/app.scss";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-FD713JR5Z8" }
});

new Vue({
  render: h => h(App)
}).$mount("#app");
