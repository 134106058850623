<template>
  <div id="pong">
    <div id="wrap">
      <div id="background"></div>
      <div
        id="startMenu"
        class="start-menu menu"
        v-show="startMenuActive"
        :class="{ active: startMenuActive }"
      >
        <h1>Relationships</h1>
        <h1 class="player-select-text">Select Your Player</h1>
        <p class="player-select-sub-text">You're on the left</p>
        <div>
          <button @click="startGame('eddie')">eddie</button>
          <button @click="startGame('eman')">emman</button>
        </div>
        <h2 class="player-select-sub-text">music will start with game.</h2>
      </div>

      <div
        id="pauseMenu"
        class="pause-menu menu"
        v-show="gamePaused"
        :class="{ active: gamePaused }"
      >
        <h1>Pause</h1>
        <button id="continueBtn" @click="resumeGame">Continue</button>
        <button id="restartBtn" @click="resetGame">Restart</button>
      </div>

      <div
        id="gameOverMenu"
        class="gameover-menu menu"
        v-show="gameOverMenuActive"
        :class="{ active: gameOverMenuActive }"
      >
        <h1 id="gameMessage" class="player-select-text" ref="gameMessage">
          {{ gameMessage }}
        </h1>
        <button id="againBtn" @click="resetGame">{{ againButton }}</button>
        <div v-html="Songs[selectedSong].player"></div>
        <iframe
          id="spotify-follow"
          src="https://open.spotify.com/follow/1/?uri=spotify:artist:4n6ycdTUrxvE0umZTB67IB&size=detail&theme=dark&show-count=0"
          width="169"
          height="56"
          scrolling="no"
          frameborder="0"
          style="border: none; overflow: hidden"
          allowtransparency="true"
        ></iframe>
      </div>

      <div id="gameplay" :class="{ active: gamePlayActive }">
        <canvas id="gameCanvas" ref="canvas"></canvas>
      </div>
    </div>

    <div class="avatars" :class="{ 'avatars--rotate': flipAvatars }">
      <div class="avatars__background"></div>
      <div class="eddie" ref="eddie">
        <inline-svg
          class="avatar"
          :src="require('@/assets/images/eddie.svg')"
        />
      </div>
      <div class="eman" ref="eman">
        <inline-svg class="avatar" :src="require('@/assets/images/eman.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InlineSvg from "vue-inline-svg";

export default {
  components: { InlineSvg },
  data() {
    return {
      key: process.env.VUE_APP_SPOTIFY_KEY,
      canvasContext: {},
      ballPositionX: 0,
      ballPositionY: 0,
      ballVelocityX: 8,
      ballVelocityY: 0,
      fps: 120,
      ballSize: 35,
      paddleWidth: 30,
      paddleHeight: 200,
      paddleOneY: 250,
      paddleOneDirectionY: null,
      paddleOneVelocityY: 15,
      paddleTwoY: 250,
      paddleTwoVelocityY: 10,
      playerOneScore: 0,
      playerTwoScore: 0,
      gamePaused: false,
      gameInProgress: false,
      scoreToWin: 5,
      difficultyLevel: 2,
      gameInterval: null,
      ballColor: "white",
      colorOptions: ["#f0ad3d", "#e8011d", "#3865cb", "#8d2e9f", "#4faa36"],
      selectedPlayer: "eddie",
      gameMessage: "",
      againButton: "",
      gameOverMenuActive: false,
      gamePlayActive: false,
      startMenuActive: false,
      Songs: {
        0: {
          songs: ["Arthurs fist", "Runaways"],
          player: `
          <iframe class="player" src="https://open.spotify.com/embed/track/51wJquGTMTqjJjX8yGUwro?si=db3cf9051b874597" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          <iframe class="player" src="https://open.spotify.com/embed/track/2DmEENCRrtbIfpXLzuYkZN?si=2761dd126f924308" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          `
        },
        1: {
          songs: ["Tell me what to do", "Endless"],
          player: `
          <iframe class="player" src="https://open.spotify.com/embed/track/1VtXxXjnwxt0LpIPYxKsUR?si=86f360aca8d147cd" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          <iframe class="player" src="https://open.spotify.com/embed/track/1aCGuG6mOTmBLNpKD9bxGE?si=7b0b1f9f74dd47c2" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          <iframe class="player" src="https://open.spotify.com/embed/track/1N4V2nnVda1Mz4ETe1Pumm?si=28e671a894574bee" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          `
        }
      },
      selectedSong: 0
    };
  },
  mounted() {
    window.scrollTo(0, 1);
    (this.ballPositionX = this.$refs.canvas.width / 2),
      (this.canvasContext = this.$refs.canvas.getContext("2d")),
      (this.ballPositionY = this.$refs.canvas.height / 2),
      (this.gamePaused = false),
      (this.gameInProgress = false),
      (this.$refs.canvas.width = window.innerWidth);
    this.$refs.canvas.height = window.innerHeight;
    this.ballPositionY = this.$refs.canvas.height / 2 - this.ballSize / 2;
    this.paddleOneY = this.$refs.canvas.height / 2 - this.paddleHeight / 2;
    this.paddleTwoY = this.$refs.canvas.height / 2 - this.paddleHeight / 2;

    this.ballVelocityY =
      this.getRandomNumber(-5, 5) * (0.25 * this.difficultyLevel);
    window.addEventListener("resize", this.windowResize);
    document.addEventListener("keydown", this.keyDown);
    document.addEventListener("keyup", this.keyUp);
    window.addEventListener("touchmove", this.moveMobile);
    window.addEventListener("mousemove", this.moveWithMouse);
    window.addEventListener("resize", this.resizeGame);

    this.startMenuActive = true;
    (this.gamePlayActive = false), (this.gameOverMenuActive = false);
    this.gamePaused = false;

    window.onblur = () => {
      if (this.gameInProgress) this.pauseGame();
    };

    this.resizeGame();
  },
  methods: {
    loginSpotify() {
      axios.post(`https://accounts.spotify.com/api/${this.key}`);
    },
    changeBallColor() {
      let min = Math.ceil(0);
      let max = Math.floor(4);
      let randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
      this.ballColor = this.colorOptions[randomNumber];
    },
    startGame(selectedPlayer = "eddie") {
      this.$emit("game-started");
      this.selectedPlayer = selectedPlayer;
      if (document.body.requestFullscreen && window.innerWidth < 1050)
        document.body.requestFullscreen();
      this.gameInProgress = true;
      this.gamePlayActive = false;
      this.startMenuActive = false;
      this.gameOverMenuActive = false;
      this.gamePaused = false;
      this.gameInterval = window.setInterval(this.render, 1000 / this.fps);
    },
    render() {
      this.moveEverything();
      this.drawEverything();
    },
    resetGame() {
      this.playerOneScore = 0;
      this.playerTwoScore = 0;
      (this.difficultyLevel = 1),
        (this.ballPositionX = this.$refs.canvas.width / 2 - this.ballSize / 2);
      this.ballPositionY = this.$refs.canvas.height / 2 - this.ballSize / 2;
      this.paddleOneY = this.$refs.canvas.height / 2 - this.paddleHeight / 2;
      this.paddleTwoY = this.$refs.canvas.height / 2 - this.paddleHeight / 2;
      (this.ballVelocityY =
        this.getRandomNumber(-5, 5) * (0.25 * this.difficultyLevel)),
        this.startGame();
    },
    togglePause() {
      if (this.gamePaused) {
        this.resumeGame();
      } else {
        this.pauseGame();
      }
    },
    pauseGame() {
      if (!this.gamePaused) {
        this.gamePaused = true;
        this.gamePlayActive = false;
        clearInterval(this.gameInterval);
      }
    },
    resumeGame() {
      if (this.gamePaused) {
        this.gamePaused = false;
this.gamePlayActive = false;
        this.startGame();
      }
    },
    windowResize() {
      this.resetBall();
      this.$refs.canvas.width = window.innerWidth;
      this.$refs.canvas.height = window.innerHeight;
      this.drawEverything();
    },
    keyDown(e) {
      e.preventDefault();
      switch (e.keyCode) {
        case 13:
          if (this.gameInProgress) this.togglePause();
          break;
        case 38:
          if (!this.gamePaused) this.paddleOneDirectionY = "up";
          break;
        case 40:
          if (!this.gamePaused) this.paddleOneDirectionY = "down";
          break;
      }
    },
    keyUp() {
      this.paddleOneDirectionY = null;
    },
    resetBall() {
      this.ballVelocityX = -this.ballVelocityX;
      this.ballVelocityY =
        this.getRandomNumber(-5, 5) * (0.25 * this.difficultyLevel);
      this.ballPositionX = this.$refs.canvas.width / 2;
      this.ballPositionY = this.$refs.canvas.height / 2;
    },
    getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    randomizeGame() {
      this.paddleTwoVelocityY =
        this.getRandomNumber(10, 20) * (0.25 * this.difficultyLevel);
    },
    gameOver(playerWon) {
      this.gameInProgress = false;
      clearInterval(this.gameInterval);
      if (playerWon) {
        this.gameMessage =
          "You won, Congrats! Here's your prize, two songs by Dyne Side";
        this.againButton = "Play again";
        this.selectedSong = 0;
      } else {
        this.gameMessage =
          "Oh snap, you lost. But don't take it personal. Listen to these songs by Dyne Side to get over it";
        this.againButton = "Try again";
        this.selectedSong = 1;
      }
      this.gamePlayActive = false;
      this.gameOverMenuActive = true;
    },
    moveEverything() {
      // Move ball
      this.ballPositionX = this.ballPositionX + this.ballVelocityX;
      // Determin player1 winner and reset or bounce ball to right
      if (
        this.ballPositionX >
        this.$refs.canvas.width - this.paddleWidth * 2 - this.ballSize / 2
      ) {
        if (
          this.ballPositionY >= this.paddleTwoY &&
          this.ballPositionY <= this.paddleTwoY + this.paddleHeight &&
          this.ballPositionX < this.$refs.canvas.width - this.paddleWidth
        ) {
          this.ballVelocityX = -this.ballVelocityX;
          if (
            this.ballPositionY >= this.paddleTwoY &&
            this.ballPositionY < this.paddleTwoY + this.paddleHeight * 0.2
          ) {
            this.ballVelocityY = -15 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleTwoY + this.paddleHeight * 0.2 &&
            this.ballPositionY < this.paddleTwoY + this.paddleHeight * 0.4
          ) {
            this.ballVelocityY = -10 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleTwoY + this.paddleHeight * 0.4 &&
            this.ballPositionY < this.paddleTwoY + this.paddleHeight * 0.6
          ) {
            this.ballVelocityY = this.getRandomNumber(-5, 5);
          } else if (
            this.ballPositionY >= this.paddleTwoY + this.paddleHeight * 0.6 &&
            this.ballPositionY < this.paddleTwoY + this.paddleHeight * 0.8
          ) {
            this.ballVelocityY = 10 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleTwoY + this.paddleHeight * 0.8 &&
            this.ballPositionY < this.paddleTwoY + this.paddleHeight
          ) {
            this.ballVelocityY = 15 * (0.25 * this.difficultyLevel);
          }
          this.changeBallColor();
        } else if (this.ballPositionX > this.$refs.canvas.width) {
          this.resetBall();
          this.playerOneScore++;
          this.difficultyLevel = this.playerOneScore * 0.5;
          if (this.playerOneScore === this.scoreToWin) this.gameOver(true);
        }
        this.randomizeGame();
      }
      // Determin player2 winner and reset or bounce ball to left
      else if (this.ballPositionX < this.paddleWidth * 2 + this.ballSize / 2) {
        if (
          this.ballPositionY >= this.paddleOneY &&
          this.ballPositionY <= this.paddleOneY + this.paddleHeight &&
          this.ballPositionX > this.paddleWidth + this.ballSize / 2
        ) {
          this.ballVelocityX = -this.ballVelocityX;
          if (
            this.ballPositionY >= this.paddleOneY &&
            this.ballPositionY < this.paddleOneY + this.paddleHeight * 0.2
          ) {
            this.ballVelocityY = -20 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleOneY + this.paddleHeight * 0.2 &&
            this.ballPositionY < this.paddleOneY + this.paddleHeight * 0.4
          ) {
            this.ballVelocityY = -10 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleOneY + this.paddleHeight * 0.4 &&
            this.ballPositionY < this.paddleOneY + this.paddleHeight * 0.6
          ) {
            this.ballVelocityY = 0 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleOneY + this.paddleHeight * 0.6 &&
            this.ballPositionY < this.paddleOneY + this.paddleHeight * 0.8
          ) {
            this.ballVelocityY = 10 * (0.25 * this.difficultyLevel);
          } else if (
            this.ballPositionY >= this.paddleOneY + this.paddleHeight * 0.8 &&
            this.ballPositionY < this.paddleOneY + this.paddleHeight
          ) {
            this.ballVelocityY = 20 * (0.25 * this.difficultyLevel);
          }
          this.changeBallColor();
        } else if (this.ballPositionX <= -this.ballSize) {
          this.resetBall();
          this.playerTwoScore++;
          if (this.playerTwoScore === this.scoreToWin) this.gameOver(false);
        }
        this.randomizeGame();
      }

      this.ballPositionY = this.ballPositionY + this.ballVelocityY;
      if (this.ballPositionY > this.$refs.canvas.height - this.ballSize / 2) {
        this.ballVelocityY = -this.ballVelocityY;
        this.ballPositionY = this.$refs.canvas.height - this.ballSize / 2;
      } else if (this.ballPositionY < this.ballSize / 2) {
        this.ballVelocityY = -this.ballVelocityY;
        this.ballPositionY = this.ballSize / 2;
      }

      // Move paddles
      if (this.paddleOneDirectionY === "up" && this.paddleOneY >= 0) {
        this.paddleOneY = this.paddleOneY - this.paddleOneVelocityY;
      } else if (
        this.paddleOneDirectionY === "down" &&
        this.paddleOneY < this.$refs.canvas.height - this.paddleHeight
      ) {
        this.paddleOneY += this.paddleOneVelocityY;
      }

      if (this.ballPositionY < this.paddleTwoY) {
        this.paddleTwoY -= this.paddleTwoVelocityY;
      } else if (this.ballPositionY > this.paddleTwoY + this.paddleHeight) {
        this.paddleTwoY += this.paddleTwoVelocityY;
      }
    },
    drawPlayerPaddle() {
      this.canvasContext.fillStyle = "white";
      this.canvasContext.fillRect(
        this.paddleWidth,
        this.paddleOneY,
        this.paddleWidth,
        this.paddleHeight
      ); // x, y, w, h
    },
    drawEverything() {
      // canvasContext.fillStyle = 'black';
      // canvasContext.fillRect(0,0,canvas.width,canvas.height);
      this.canvasContext.clearRect(
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
      this.canvasContext.fillStyle = this.ballColor;
      this.canvasContext.beginPath();
      this.canvasContext.arc(
        this.ballPositionX,
        this.ballPositionY,
        this.ballSize / 2,
        0,
        Math.PI * 2,
        true
      );
      this.canvasContext.fill();

      this.drawPlayerPaddle();

      this.canvasContext.fillStyle = "white";
      this.canvasContext.fillRect(
        this.$refs.canvas.width - this.paddleWidth - this.paddleWidth,
        this.paddleTwoY,
        this.paddleWidth,
        this.paddleHeight
      ); // x, y, w, h

      this.canvasContext.fillStyle = "rgba(255,255,255,0.2)";
      this.canvasContext.font = "200px 'Roboto', Arial";
      this.canvasContext.textAlign = "center";
      this.canvasContext.fillText(
        this.playerOneScore,
        this.$refs.canvas.width * 0.25,
        this.$refs.canvas.height / 2 + 75
      );

      this.canvasContext.fillStyle = "rgba(255,255,255,0.2)";
      this.canvasContext.font = "200px 'Roboto', Arial";
      this.canvasContext.textAlign = "center";
      this.canvasContext.fillText(
        this.playerTwoScore,
        this.$refs.canvas.width * 0.75,
        this.$refs.canvas.height / 2 + 75
      );

      this.canvasContext.strokeStyle = "rgba(255,255,255,0.2)";
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(this.$refs.canvas.width / 2, 0);
      this.canvasContext.lineTo(
        this.$refs.canvas.width / 2,
        this.$refs.canvas.height
      );
      this.canvasContext.stroke();
    },
    moveMobile({ changedTouches }) {
      this.paddleOneY = changedTouches[0].clientY;
      this.drawEverything();
    },
    moveWithMouse({ y }) {
      this.paddleOneY = y;
      this.drawEverything();
    },
    resizeGame() {
      if (window.innerWidth < 1050) {
        this.fps = 40;
        this.ballSize = 25;
        this.paddleWidth = 15;
        this.paddleHeight = 100;
        this.paddleOneY = 250;
      } else {
        this.fps = 120;
        this.ballSize = 35;
        this.paddleWidth = 30;
        this.paddleHeight = 200;
        this.paddleOneY = 250;
      }
    }
  },
  computed: {
    flipAvatars() {
      return this.selectedPlayer == "eman";
    }
  }
};
</script>

<style lang="scss">
@keyframes moveArm {
  from {
    transform: translate(-2%, 1%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes rotateLever {
  from {
    transform: rotate(2deg) translate(-1%, -3%);
  }
  to {
    transform: rotate(0) translate(0);
  }
}

@keyframes moveArmAlt {
  from {
    transform: translate(2%, 1%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes rotateLeverAlt {
  from {
    transform: rotate(-2deg) translate(1%, 3%);
  }
  to {
    transform: rotate(0) translate(0);
  }
}

#pong {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(0, 0, 0, 0.678);
}
#wrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
#wrap > div {
  position: absolute;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  filter: blur(20px);
}

#wrap > div.active {
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
  filter: blur(0);
}

#wrap > #gameplay {
  opacity: 1;
  z-index: 0;
  filter: blur(0);
  height: 100%;
  width: 100%;
}

#wrap > div.active ~ #gameplay {
  opacity: 0.75;
  filter: blur(10px);
}

#gameCanvas {
  width: 100%;
  height: 100%;
}

#gameOverMenu {
  display: flex;
  flex-direction: column;
}
button {
  background-color: white;
  border: 0;
  border-radius: 200px;
  padding: 16px 24px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.25px;
  font-size: 16px;
  margin: 16px;
  min-width: 160px;
  width: auto;
  outline: none;
  transition: 0.25s;
  opacity: 0.75;
  cursor: pointer;
  align-self: center;
}

button:hover {
  opacity: 1;
}

h1 {
  color: white;
  text-transform: uppercase;
  font-size: 48px;
  margin-top: 0;
  letter-spacing: 0.2em;
  line-height: 1.5em !important;
}

.player-select-text {
  font-size: 1.5em;
  margin: 10px 0;
  @media (max-width: 768px) {
    font-size: 1em;
  }
}

.player-select-sub-text {
  font-size: 1em;
  margin: 10px 0;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
}

.start-menu,
.pause-menue,
.gameover-menu {
  max-width: 570px;
}
.menu {
 background: rgba(245, 245, 245, 0.19);
  padding: 20px;
  @media (max-width: 768px) {
    padding: 20px;
    position: fixed !important;
    top: 0;
    z-index: 9 !important;
  }
}
/* bottom section with the avatars */
.avatars {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/pong-bg.png");
    background-position: center top;
    background-size: cover;
    opacity: 0.5;
  }
  &--rotate {
    flex-direction: row-reverse;
    .eddie,
    .eman {
      transform: rotateY(180deg);
    }
  }
  .eddie,
  .eman {
    align-items: flex-end;
    z-index: 1;
    flex: 1;
    max-width: 450px;
    @media (max-width: 800px) {
      width: 40%;
    }
    img {
      width: 100%;
    }
  }
  #eddie #right-arm {
    animation: moveArm infinite 2s alternate;
  }
  #eddie #lever {
    animation: rotateLever infinite 2s alternate;
  }
  #eman #right-arm {
    animation: moveArmAlt infinite 2s alternate;
  }
  #eman #lever {
    animation: rotateLeverAlt infinite 2s alternate;
  }
}
#spotify-follow {
  margin: 20px auto;
}
</style>
