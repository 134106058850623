<template>
  <section id="brain-background" :class="{blur: blur && brainIsAnimated}">
    <div class="rainbow" :class="{ active: brainIsAnimated, show: showBrain }">
      <inline-svg
        :src="require('@/assets/images/rainbow.svg')"
        @loaded="loadedImage"
      />
    </div>

    <!-- <div class="shine" :class="{ active: brainIsAnimated, show : showBrain }">
      <inline-svg 
        :src="require('@/assets/images/shine.svg')"
        @loaded="++loadedImage"
      />
    </div> -->

    <div class="brain" :class="{ active: brainIsAnimated, show: showBrain }">
      <inline-svg
        :src="require('@/assets/images/brain.svg')"
        @loaded="loadedImage"
      />
    </div>

    <div class="drops" :class="{ active: brainIsAnimated, show: showBrain }">
      <inline-svg
        :src="require('@/assets/images/drops.svg')"
        @loaded="loadedImage"
      />
    </div>
  </section>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  components: { InlineSvg },
  props: {
    brainisReady: {
      type: Boolean,
      default: false
    },
    showBrain: {
      type: Boolean,
      default: false,
    },
    brainIsAnimated: {
      type: Boolean,
      default: false
    },
    blur: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  methods: {
    loadedImage() {
      this.loadedImages < 2 ? ++this.loadedImages : this.$emit("loaded");
    },

  },
};
</script>

<style lang="scss">
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes dropsTopMove {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-2%, -2%);
  }
}
@keyframes dropsBottomMove {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-2%, 2%);
  }
}
@keyframes shineSlideFull {
  from {
    left: -20%;
  }
  to {
    left: 100%;
  }
}
@keyframes shineSlide {
  from {
    left: -20%;
  }
  to {
    left: 40%;
  }
}
#brain-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.brain,
.rainbow,
.drops,
.shine {
  height: 100%;
  position: absolute;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: 0;
  opacity: 0;
  transition: all 1.5s ease;
  &.show {
    opacity: 1;
  }
  svg {
    position: absolute;
    transition: all 1.5s ease;
    height: 100%;
    width: 100%;
    left: 0;
    align-self: center;
  }
}

#Big-gear,
#Little-gear {
  transform-box: fill-box;
  transform-origin: center;
  animation: spin 7s linear infinite;
}

.brain,
.drops {
  &.active {
    svg {
      left: 50%;
    }
  }
}

.drops {
  svg {
    transition-delay: 0.1s;
  }
  [id^="Drop"] {
    transition: all 1.5s ease;
    transform: translate(0, 0);
  }
  #Drop1 {
    animation: dropsTopMove 2.5s alternate infinite;
  }
  #Drop2 {
    animation: dropsTopMove 3s alternate infinite;
  }
  #Drop3 {
    animation: dropsTopMove 3.5s alternate infinite;
  }
  #Drop4 {
    animation: dropsBottomMove 3s alternate infinite;
  }
  #Drop5 {
    animation: dropsBottomMove 2s alternate infinite;
  }
}

.rainbow {
  transition-delay: 0.5s;
  #Rainbow {
    transition: all 1.5s ease;
    transition-delay: 0;
    transform: scaleX(0.5);
  }
  &.active {
    #Rainbow {
      transform: scaleX(1.5);
    }
  }
}

.shine {
  svg {
    left: -10%;
    animation: shineSlide 1.5s infinite;
  }
  &.active svg {
    animation: shineSlideFull 2.8s infinite;
  }
}

.spin {
  animation: spin 7s linear infinite;
}

.blur {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    backdrop-filter: blur(4px);
  }
}
</style>