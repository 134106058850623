<template>
  <div id="app" :class="{ active: showContent }">
    <BrainBackground
      @loaded="brainIsReady"
      :brainIsAnimated="showContent"
      :showBrain="showBrain"
      :blur="true"
    />

    <h1 class="loader" v-if="!showBrain">Collecting Our Thoughts</h1>

    <section id="content" :class="{active: showContent}">
      <Pong v-show="showContent" @game-started="playTrack"/>
    </section>

    <footer id="footer" :class="{active: showContent}">
      <audioTrack ref="audioplayer" :active="isAudioPlayerActive" />

      <div class="social-icons">
        <a
          class="social-icon social-icon--spotify"
          name="spotify"
          target="_blank"
          @click="pixelEvent()"
          href="https://open.spotify.com/artist/4n6ycdTUrxvE0umZTB67IB?si=l5C9TenrSTyhEOcUTjciEw"
          ><span class="icon-spotify"></span
        ></a>
        <a
          class="social-icon social-icon--insta"
          name="instagram"
          target="_blank"
          @click="pixelEvent('instagram')"
          href="https://www.instagram.com/dyneside/"
          ><span class="icon-instagram"></span
        ></a>
        <a
          class="social-icon social-icon--twitter"
          name="twitter"
          target="_blank"
          @click="pixelEvent('twitter')"
          href="https://mobile.twitter.com/dyneside"
          ><span class="icon-twitter"></span
        ></a>
        <a
          class="social-icon social-icon--facebook"
          name="facebook"
          target="_blank"
          @click="pixelEvent('facebook')"
          href="https://m.facebook.com/DYNESIDE/"
          ><span class="icon-facebook2"></span
        ></a>
        <a
          class="social-icon social-icon--youtube"
          name="youtube"
          target="_blank"
          @click="pixelEvent('youtube')"
          href="https://www.youtube.com/watch?v=cxhqDz6yzjk"
        >
          <span class="icon-youtube"></span
        ></a>
      </div>
    </footer>
  </div>
</template>

<script>
import AudioTrack from "@/components/AudioTrack";
import BrainBackground from "@/components/BrainBackground";
import Pong from "@/components/PongGame/Pong";

export default {
  name: "App",
  components: {
    AudioTrack,
    BrainBackground,
    Pong,
  },
  data() {
    return {
      showBrain: false,
      showContent: false,
      isAudioPlayerActive: false,
      windowOriantation: "",
    };
  },
  methods: {
    pixelEvent(event) {
      window.fbq("track", "ViewContent", {
        content_name: "cilcked social link",
        content: event,
      });

      window.fbq("trackCustom", "SocialClick", {
        content_name: "cilcked social link",
        content: event,
      });

      window.fbq("trackCustom", `track_${event}`);
    },
    brainIsReady() {
      setTimeout(() => {
        this.showBrain = true;
      }, 1500);
      setTimeout(() => {
        this.showContent = true;
      }, 3000);
    },
    orientationChange() {
      this.windowOriantation =
        window.orientation > 0 || window.orientatione < 0
          ? "landscape"
          : "portriat";
    },
    playTrack() {
      this.isAudioPlayerActive = true;
    }
  },
  mounted() {
    window.fbq("init", "2554149204888478");
    window.fbq("track", "PageView");
    window.addEventListener("orientationchange", this.orientationChange);
    this.orientationChange();
  },
};
</script>

<style lang="scss">
@keyframes loader {
  0% {
    content: ".";
  }
  50% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

* {
  box-sizing: border-box;
}
body {
  overflow: hidden;
}
#app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Comfortaa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-background;
  background-image: url("~@/assets/images/texture.png");
  background-repeat: repeat;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
  transition: all 1.5s ease;
  z-index: 0;
  &.active {
    &.p {
      background-color: lighten($color-p, 24);
    }
    &.e {
      background-color: lighten($color-e, 47);
    }
    &.r {
      background-color: lighten($color-r, 45);
    }
    &.m {
      background-color: lighten($color-m, 50);
    }
    &.a {
      background-color: lighten($color-a, 45);
    }
  }
}

#content {
  flex: 1;
  width: 100%;
  z-index: 1;
  opacity: 0;
  transition: ease 1.5s;
  &.active {
    opacity: 1;
  }
}

.social-icons {
  margin-right: auto;
  z-index: 1;
}
.social-icon {
  display: inline-flex;
  color: $color-primary;
  text-decoration: none;
  margin: 0 5px;
  padding: 5px;
  font-size: 25px;
  &:hover {
    transform: scale(1.3);
  }
  &--facebook {
    &:hover {
      color: $color-p;
    }
  }
  &--spotify {
    &:hover {
      color: $color-e;
    }
  }
  &--youtube {
    &:hover {
      color: $color-r;
    }
  }
  &--insta {
    &:hover {
      color: $color-m;
    }
  }
  &--twitter {
    &:hover {
      color: $color-a;
    }
  }
}

.loader {
  display: block;
  position: absolute;
  font-size: 45px;
  color: black;
  &:after {
    content: "";
    position: absolute;
    animation: loader 1s infinite linear;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.no-wrap {
  white-space: nowrap;
}

#footer {
  padding: 16px;
  width: 100%;
  z-index: 1;
  transition: ease 1.5s;
  @media (min-width: 768px) {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding-bottom: 8em;
  }
  &.active {
  background: #2f2f39;

  }
}
</style>
